import OneSignalError from './OneSignalError';
import OneSignal from '../OneSignal';
import { ApiUsageMetricEvent, ApiUsageMetricKind } from '../managers/MetricsManager';

export enum DeprecatedApiReason {
  HttpPermissionRequest
}

export class DeprecatedApiError extends OneSignalError {
  constructor(reason: DeprecatedApiReason) {
    switch (reason) {
      case DeprecatedApiReason.HttpPermissionRequest:
        super('The HTTP permission request has been deprecated. Please remove any custom popups from your code.');
        this.reportUsage();
        break;
    }
  }

  reportUsage() {
    if (typeof OneSignal !== 'undefined' && OneSignal.context && OneSignal.context.metricsManager) {
      OneSignal.context.metricsManager.reportEvent(new ApiUsageMetricEvent(ApiUsageMetricKind.HttpPermissionRequest));
    }
  }
}
